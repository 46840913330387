import { useEffect, useState } from "react";

const useConnectionStatus = () => {
  const hasWindow = typeof window !== "undefined";

  const [online, setOnline] = useState(navigator?.onLine);
  const [networkInfo, setNetworkInfo] = useState({} as any);

  const getNetworkConnection = () => {
    return navigator.connection || null;
  };

  const getNetworkConnectionInfo = () => {
    const connection = getNetworkConnection();

    console.log({ connection });

    if (!connection) {
      return {};
    }
    return {
      effectiveType: (connection as any)?.effectiveType,
    };
  };

  useEffect(() => {
    if (!!hasWindow) {
      const handleOnline = () => setOnline(true);
      const handleOffline = () => setOnline(false);
      const handleConnectionChange = () =>
        setNetworkInfo(getNetworkConnectionInfo());

      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);

      const connection = getNetworkConnection();
      connection?.addEventListener("change", handleConnectionChange);

      return () => {
        window.removeEventListener("online", handleOnline);
        window.removeEventListener("offline", handleOffline);
        connection?.removeEventListener("change", handleConnectionChange);
      };
    }

    //eslint-disable-next-line
  }, [hasWindow]);

  return {
    online,
    networkInfo,
  };
};

export default useConnectionStatus;
