/** 26122022 - Gaurav - CA-1609: Optimised Error Alerts to remove local state and use redux context instead */
import React from "react";

import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { hideApiErrorAlert } from "../../../actions/alertActions";

const ApiErrorUserAlert = (props: any) => {
  const { hideApiErrorAlert, alertStore } = props;

  console.log("ApiErrorUserAlert", { props });

  const errorMessage =
    props?.errorMessage ??
    `There was an error updating your response to the server, probably due to
  network congestion. Your response is valuable to us. Please try
  submitting your response again after some time. If issue persists, we
  request you to contact our helpdesk.`;

  if (!alertStore.showApiErrorAlert) return <React.Fragment></React.Fragment>;

  return (
    <Collapse in={!!alertStore.showApiErrorAlert}>
      <Alert
        severity="error"
        action={
          !props?.doNotDismiss && (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                hideApiErrorAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
        sx={{ mb: 2, textAlign: "left" }}
      >
        <AlertTitle>Error</AlertTitle>
        {errorMessage}
      </Alert>
    </Collapse>
  );
};

const mapStateToProps = (state: any) => ({
  alertStore: state.alerts,
});

export default connect(mapStateToProps, { hideApiErrorAlert })(
  ApiErrorUserAlert
);
