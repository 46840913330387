/** 03102022 - Gaurav - CA-1518: Change Survey app title */

export const setTitle = (titleText: string) => {
  const title = document.getElementById("#appTitle");
  !!title && (title.textContent = titleText);
};

export const setBackground = (add = true, darkMode = false) => {
  return;

  // const gradientClass = "gradient-bg";
  // const darkGradientClass = "dark-gradient";

  // const header = document.getElementsByTagName("header")[0];

  // if (!add) {
  //   document.body.classList.remove(gradientClass);
  //   document.body.classList.remove(darkGradientClass);

  //   !!header && header.classList.remove(gradientClass);
  //   !!header && header.classList.remove(darkGradientClass);

  //   return;
  // }

  // if (!document.body.classList.contains(gradientClass)) {
  //   document.body.classList.add(gradientClass);
  // }

  // if (!!header && !header.classList.contains(gradientClass)) {
  //   header.classList.add(gradientClass);
  // }

  // if (!!darkMode) {
  //   document.body.classList.add(darkGradientClass);
  //   !!header && header.classList.add(darkGradientClass);
  // } else {
  //   document.body.classList.remove(darkGradientClass);
  //   !!header && header.classList.remove(darkGradientClass);
  // }

  // return;
};
