import { ReducerActionProps } from "../models/app.model";
import {
  SurveyFormStateActionType,
  SurveyFormStateProps,
} from "../models/survey-redux.model";

const initialState = {
  isSurveySubmitted: false,
  isSurveySubmissionError: false,

  showAdhocAddlInputPage: false,
  adHocSurveyInstance: null,
  adHocSurveyUserData: null,

  showResponsesApiErrorAlert: false,
  showSavedResponsesApiAlert: false,
  currentSurveyPage: 0,
  isLoading: false,
  isPendingRequiredResponses: false,
  highlightRequiredFields: false,
} as SurveyFormStateProps;

const SurveyFormReducer = (
  state = initialState,
  action: ReducerActionProps
) => {
  switch (action.type) {
    case SurveyFormStateActionType.SET_CURRENT_SURVEY_PAGE:
      return {
        ...state,
        currentSurveyPage: action.payload,
        highlightRequiredFields: false,
      };

    case SurveyFormStateActionType.SET_SURVEY_SUBMISSION_FROM_API:
      return {
        ...state,
        isSurveySubmitted: action.payload.isSurveySubmitted,
        showAdhocAddlInputPage: action.payload.showAdhocAddlInputPage,
        highlightRequiredFields: false,
        isSurveySubmissionError:
          action.payload?.isSurveySubmissionError ?? false,
      };

    case SurveyFormStateActionType.SET_ADHOC_SURVEY_USERDATA:
      return {
        ...state,
        adHocSurveyUserData: { ...action.payload },
      };

    case SurveyFormStateActionType.SET_PENDING_RESPONSES:
      return {
        ...state,
        isPendingRequiredResponses: true,
        highlightRequiredFields: true,
      };

    case SurveyFormStateActionType.CLEAR_PENDING_RESPONSES:
      return {
        ...state,
        isPendingRequiredResponses: false,
      };

    case SurveyFormStateActionType.CLEAR_HIGHLIGHT_REQUIRED_FIELDS:
      return {
        ...state,
        highlightRequiredFields: false,
      };

    default:
      return state;
  }
};

export default SurveyFormReducer;
