import { combineReducers } from "redux";
import AppReducer from "./appReducer";
import AlertReducer from "./alertReducer";
import SubscriptionReducer from "./subscriptionReducer";
import SurveyReducer from "./surveyReducer";
import SurveyFormReducer from "./surveyFormReducer";
import SurveyResponseReducer from "./surveyResponseReducer";

export default combineReducers({
  app: AppReducer,
  alerts: AlertReducer,
  subscription: SubscriptionReducer,
  survey: SurveyReducer,
  surveyForm: SurveyFormReducer,
  surveyResponse: SurveyResponseReducer,
});
