/** 23122022 - Gaurav - CA-1609: Subscription component > multiple optimisations */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import usePageResize from "../../hooks/usePageResize";

import NetworkAlerts from "../shared/util/NetworkAlerts";
import Stack from "@mui/material/Stack";
import Loader from "../loader/Loader";
import ColouredBox from "../shared/ColouredBox";
import ApiErrorUserAlert from "../shared/util/ApiErrorUserAlert";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { connect } from "react-redux";

import {
  fetchSubscription,
  updateSubscription,
} from "../../actions/subscriptionActions";
import PropTypes from "prop-types";
import { SubscriptionStateProps } from "../../models/subscription.model";
import { setTitle } from "../shared/util/globalFunctions";

const Subscription = ({
  subscription,
  fetchSubscription,
  updateSubscription,
}: any) => {
  const { custId } = useParams();
  const { isMobileView } = usePageResize();

  /** 23122022 - Gaurav - CA-1609: Moved setTitle here from effect  */
  React.useLayoutEffect(() => {
    setTitle("Subscriptions"); // 3102022 - Gaurav - CA-1518: Change Survey app title
  }, []);

  const [subscriptionChanged, setSubscriptionChanged] = useState(false);
  const subscriptionChangedSuffix = `Please note, changes may take several hours to take effect.`;

  const {
    orgData,
    apiErrorMessage,
    doNotDismiss,
    isUnsubscribed,
    isUpdating,
    apiError,
  } = subscription as SubscriptionStateProps;

  console.log("Subscription", { ...subscription, custId, isMobileView });

  /** 23122022 - Gaurav - CA-1609: Removed isInitialising prop. Fetch only when no org data and no api error */
  React.useEffect(() => {
    if (!orgData && !apiError) {
      console.log("Subscription : useEffect : fetchSubscription");
      fetchSubscription(custId);
    }

    // eslint-disable-next-line
  }, [orgData, apiError]);

  /** 23122022 - Gaurav - CA-1609: Removed isInitialising prop. Show loader when no org data and no api error */
  if (!orgData && !apiError) {
    return <Loader />;
  }

  /** 23122022 - Gaurav - CA-1609: Removed local state and handled update api from user action event instead of in useEffect on state change */
  const handleUpdateSubscription = () => {
    console.log("Subscription : handleUpdateSubscription");
    updateSubscription(custId);
    setSubscriptionChanged(true);
  };

  return (
    <Container sx={{ marginTop: "5rem" }}>
      <NetworkAlerts />

      <Stack spacing={3} sx={{ textAlign: "center" }}>
        {!!orgData?.logoUrl && (
          <img
            className="logo logo-fixed-extra-width logo-contained"
            src={orgData?.logoUrl}
            alt={orgData?.name}
          />
        )}
        {!!orgData?.name && <h2>{orgData?.name}</h2>}
        <ColouredBox padding={isMobileView ? "20px" : "50px"}>
          {!!apiError && (
            <ApiErrorUserAlert
              errorMessage={apiErrorMessage}
              doNotDismiss={doNotDismiss}
            />
          )}

          {!!orgData && (
            <Stack spacing={3} sx={{ textAlign: "left" }}>
              <p style={{ fontSize: "1.5rem", fontWeight: 500 }}>
                Subscription status
              </p>
              {!!isUpdating && <Loader />}
              {!isUpdating && (
                <p style={{ fontSize: "1.25rem" }}>
                  You are currently{" "}
                  <span
                    style={{
                      padding: ".25rem",
                      backgroundColor: !!isUnsubscribed
                        ? "rgba(255, 0, 0, 0.2)"
                        : "rgba(51, 204, 51, 0.2)",
                      borderRadius: ".2rem",
                    }}
                  >
                    {!!isUnsubscribed ? "unsubscribed from" : "subscribed to"}
                  </span>{" "}
                  <b>our newsletters, surveys, and promotional</b> emails.{" "}
                  {!!subscriptionChanged &&
                    !apiError &&
                    subscriptionChangedSuffix}
                </p>
              )}
              {!isUpdating && (
                <Button
                  variant="contained"
                  color={!!isUnsubscribed ? "success" : "error"}
                  size="large"
                  sx={{
                    textTransform: "none",
                    fontSize: "1.25rem",
                    padding: "6px 12px",
                    lineHeight: 1.5,
                    width: isMobileView ? "auto" : "fit-content",
                  }}
                  onClick={handleUpdateSubscription}
                >
                  {!!isUnsubscribed ? "Subscribe" : "Unsubscribe"}
                </Button>
              )}
            </Stack>
          )}
        </ColouredBox>
      </Stack>
    </Container>
  );
};

Subscription.propTypes = {
  subscription: PropTypes.object.isRequired,
  fetchSubscription: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
};

const mapStateToProps = (state: any) => ({
  subscription: state.subscription,
});

export default connect(mapStateToProps, {
  fetchSubscription,
  updateSubscription,
})(Subscription);
