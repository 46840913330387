/** 23122022 - Gaurav - CA-1609: Removed isInitialising prop */
import { ReducerActionProps } from "../models/app.model";
import {
  SubscriptionActionType,
  SubscriptionStateProps,
} from "../models/subscription.model";

const initialState = {
  apiErrorMessage: null,
  orgData: null,
  isUnsubscribed: false,
  doNotDismiss: true,
  apiError: false,
  isUpdating: false,
} as SubscriptionStateProps;

const SubscriptionReducer = (
  state = initialState,
  action: ReducerActionProps
) => {
  switch (action.type) {
    case SubscriptionActionType.FETCH_SUBSCRIPTION_STATUS:
      return {
        ...state,
        orgData: {
          code: action.payload.data.holdingOrg.code,
          logoUrl: action.payload.data.holdingOrg.logoUrl,
          name: action.payload.data.holdingOrg.name,
        },
        isUnsubscribed: action.payload.data.isUnsubscribed,
        apiErrorMessage: null,
        apiError: false,
        isUpdating: false,
      };

    case SubscriptionActionType.UPDATE_SUBSCRIPTION_STATUS:
      return {
        ...state,
        isUnsubscribed: action.payload.data.isUnsubscribed,
        apiErrorMessage: null,
        doNotDismiss: true,
        apiError: false,
        isUpdating: false,
      };

    case SubscriptionActionType.ON_FETCH_API_ERROR:
      return {
        ...state,
        orgData: null,
        isUnsubscribed: false,
        apiErrorMessage: action.payload.apiErrorMessage,
        apiError: true,
        isUpdating: false,
      };

    case SubscriptionActionType.ON_UPDATE_API_ERROR:
      return {
        ...state,
        doNotDismiss: false,
        apiErrorMessage: action.payload.apiErrorMessage,
        apiError: true,
        isUpdating: false,
      };

    case SubscriptionActionType.SET_UPDATING:
      return {
        ...state,
        isUpdating: true,
      };

    default:
      return state;
  }
};

export default SubscriptionReducer;
