export const kBackendUrl = `${process.env.REACT_APP_CUSTOM_API_URL}`;

export interface ReducerActionProps {
  type: string;
  payload?: any;
}

export interface AppStateProps {
  isApple: boolean;
  prefersDarkMode: boolean;
}

export enum AppActionType {
  SET_OS_TYPE = "SET_OS_TYPE",
  SET_DARK_MODE_PREFERENCE = "SET_DARK_MODE_PREFERENCE",
}

export interface RequiredDialogProps {
  open: boolean;
  handleClose: () => void;
  dialogContentText: string[];
}
