/** 23122022 - Gaurav - CA-1609: Removed react-spinners library and used uniform loading spinner available from MUI for all platforms */
import React from "react";
import "./Loader.scss";
import CustomCircularProgress from "../shared/util/CustomCircularProgress";

const Loader = () => {
  return (
    <div className="spinner-container">
      <CustomCircularProgress />
    </div>
  );
};

export default Loader;
