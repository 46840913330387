export enum DataDomainConfig {
  Customer = "Customer",
  Product = "Product",
  Revenue = "Revenue",
  Cost = "Cost",
  Comm = "Comm",
  Resp = "Resp",
  Nps = "Nps",
  ProfitEdge = "ProfitEdge",
  MarketPlace = "MarketPlace",
  System = "System", //not used on ui
}

export enum EntityStatusCode {
  Terminated = "Terminated", //-5,
  Unpublished = "Unpublished", //-2,
  Deleted = "Deleted", //-1
  Inactive = "Inactive", //0
  Active = "Active", //1
  Published = "Published", //2
  InDraft = "InDraft", //4
  Launched = "Launched", //5

  Pending = "Pending", //6,
  InProgress = "InProgress", //7,
  Expired = "Expired", //8,
  Submitted = "Submitted", //9
}

//[Surveys].[SurveyType]
export enum SurveyTypeCode {
  Campaign = "Campaign", //1002000
  AdHoc = "AdHoc", //1002001
}

//[Surveys].[SurveyPageType]
export enum SurveyPageTypeCode {
  Single = "Single", //1003000
  Multi = "Multi", //1003001
}

export enum SurveySubmissionStatus {
  Pending = "Pending",
  InProgress = "InProgress",
  Submitted = "Submitted",
  Expired = "Expired",
}

//[SurveyQuestions].[QuestionTypeId]
export enum SurveyQuestionTypeCode {
  DropDown = "DropDown", //1004000
  EmojiRating = "EmojiRating", //1004001
  LikertCheck = "LikertCheck", //1004002
  LikertRadio = "LikertRadio", //1004003
  MatrixCheck = "MatrixCheck", //1004004
  MatrixRadio = "MatrixRadio", //1004005
  SingleChoiceRadioBox = "SingleChoiceRadioBox", //1004006
  MultiChoiceCheckHorizontal = "MultiChoiceCheckHorizontal", //1004007
  SingleChoiceRadioHorizontal = "SingleChoiceRadioHorizontal", //1004008
  StarRating = "StarRating", //1004009
  SingleTextInput = "SingleTextInput", //1004010
  SingleTextArea = "SingleTextArea", //1004011
  ButtonSelection = "ButtonSelection", //1004012
}

//[SurveyQuestions].[ValueRowBindingTypeId]
export enum SurveyValueRowBindingTypeCode {
  Individual = "Individual", //1005000
  Common = "Common", //1005001
}
