import { AlertActionType, AlertStateProps } from "../models/alert.model";
import { ReducerActionProps } from "../models/app.model";

const initialState = {
  showSavedDataAlert: false,
  showApiErrorAlert: false,
  isMainAppLoading: false,
} as AlertStateProps;

const AlertReducer = (state = initialState, action: ReducerActionProps) => {
  switch (action.type) {
    case AlertActionType.SHOW_SAVED_DATA_ALERT:
      return {
        ...state,
        showSavedDataAlert: true,
        isMainAppLoading: false,
      };

    case AlertActionType.HIDE_SAVED_DATA_ALERT:
      return {
        ...state,
        showSavedDataAlert: false,
      };

    case AlertActionType.SHOW_API_ERROR_ALERT:
      return {
        ...state,
        showApiErrorAlert: true,
        isMainAppLoading: false,
      };

    case AlertActionType.HIDE_API_ERROR_ALERT:
      return {
        ...state,
        showApiErrorAlert: false,
      };

    case AlertActionType.SET_MAIN_LOADING_ON:
      return {
        ...state,
        isMainAppLoading: true,
      };

    case AlertActionType.SET_MAIN_LOADING_OFF:
      return {
        ...state,
        isMainAppLoading: false,
      };

    case AlertActionType.HIDE_ALL_ALERTS:
      return {
        ...state,
        showSavedDataAlert: false,
        showApiErrorAlert: false,
      };

    default:
      return state;
  }
};

export default AlertReducer;
