import React from "react";

import Alert from "@mui/material/Alert";
import usePageResize from "../../../hooks/usePageResize";
import useConnectionStatus from "../../../hooks/useConnectionStatus";

const NetworkAlerts = () => {
  const { isMobileView } = usePageResize();
  const { online, networkInfo } = useConnectionStatus();

  return (
    <div
      style={{
        position: isMobileView ? "absolute" : "relative",
        top: isMobileView ? ".5rem" : "auto",
        right: isMobileView ? ".5rem" : "auto",
      }}
    >
      {!online && (
        <Alert severity="error">
          {!!isMobileView
            ? "Offline"
            : "Network is offline or with intermittent disconnects"}
          .
        </Alert>
      )}
      {networkInfo?.effectiveType === "2g" && (
        <Alert severity="warning">
          {!!isMobileView
            ? "Slow network"
            : "You may be experiencing slow network connection."}
        </Alert>
      )}
    </div>
  );
};

export default NetworkAlerts;
