import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { HashRouter as Router } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("centriqe")!);
root.render(
  // REACT STRICT MORE CAUSED THE COMPONENTDIDMOUNT OR useEffect TO RUN TWICE IN DEVELOPMENT MODE, THUS CALLING AN API TWICE
  // <React.StrictMode>
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
  // </React.StrictMode>
);
