import { AppActionType } from "../models/app.model";

export const setOs = () => (dispatch: any) => {
  const payload = { isApple: false, isApplePhone: false };

  const userAgentData = (navigator as any)?.userAgentData;

  if (
    !!userAgentData &&
    !!userAgentData?.platform &&
    userAgentData?.platform !== ""
  ) {
    payload.isApple = userAgentData.platform.includes("mac");
  } else {
    payload.isApple =
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator?.platform) ||
      // iPad on iOS 13 detection
      navigator?.userAgent?.includes("Mac");

    //12122022 - Gaurav - CA-1633 fix: determine if user is on iPhone
    payload.isApplePhone = [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator?.platform);
  }

  console.log("AppState : setOs", { payload });

  dispatch({ type: AppActionType.SET_OS_TYPE, payload });
};

export const setDarkModePreference =
  (prefersDarkModeProp: boolean) => (dispatch: any) =>
    dispatch({
      type: AppActionType.SET_DARK_MODE_PREFERENCE,
      payload: prefersDarkModeProp,
    });
