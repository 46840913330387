/** 23122022 - Gaurav - CA-1609: Dispatch show error alert on api failed response */
import axios from "axios";
import { setTitle } from "../components/shared/util/globalFunctions";
import { kBackendUrl } from "../models/app.model";
import { SubscriptionActionType } from "../models/subscription.model";
import { displayApiErrorAlert, hideAllAlerts } from "./alertActions";

export const fetchSubscription =
  (custId: string) => async (dispatch: any, currentState: any) => {
    dispatch(hideAllAlerts());

    try {
      const response = await axios.get(
        `${kBackendUrl}/cust/unsubscribe/${custId}`
      );

      console.log("fetchSubscription API response", { response });

      /** 03102022 - Gaurav - CA-1518: Change Survey app title */
      const orgName =
        response?.data?.memberOrg?.name ?? response?.data?.holdingOrg?.name;
      const orgNameTitlePrefix = !!orgName ? `${orgName} | ` : "";

      orgNameTitlePrefix !== "" &&
        setTitle(`${orgNameTitlePrefix}Subscriptions`);

      dispatch({
        type: SubscriptionActionType.FETCH_SUBSCRIPTION_STATUS,
        payload: response,
      });
    } catch (error: any) {
      console.log("fetchSubscription API error", { error });

      const apiErrorMessage =
        error?.response?.status === 500
          ? `There was an error getting your subscription status from the server, probably due to
      scheduled server maintenance. Your association is valuable to us. Please try refreshing the browser after some time. If issue persists, we
      request you to contact our helpdesk`
          : `There was an error getting your subscription status from the server, probably due to
      network congestion. Your association is valuable to us. Please try refreshing the browser after some time. If issue persists, we
      request you to contact our helpdesk with the following information - status: ${error?.response?.status}, message: ${error?.response?.statusText}`;

      dispatch(displayApiErrorAlert());
      dispatch({
        type: SubscriptionActionType.ON_FETCH_API_ERROR,
        payload: { apiErrorMessage },
      });
    }
  };

export const updateSubscription =
  (custId: string) => async (dispatch: any, currentState: any) => {
    dispatch(hideAllAlerts());
    dispatch(setUpdating());

    try {
      const response = await axios.put(
        `${kBackendUrl}/cust/unsubscribe/${custId}`
      );

      console.log("updateSubscription API response", { response });

      dispatch({
        type: SubscriptionActionType.UPDATE_SUBSCRIPTION_STATUS,
        payload: response,
      });
    } catch (error: any) {
      console.log("updateSubscription API error", { error });

      const apiErrorMessage = `There was an error updating your subscription status on the server, probably due to
      network congestion. Your association is valuable to us. Please try again after some time. If issue persists, we
      request you to contact our helpdesk quoting the following - status: ${error?.response?.status}, message: ${error?.response?.statusText}`;

      dispatch(displayApiErrorAlert());
      dispatch({
        type: SubscriptionActionType.ON_UPDATE_API_ERROR,
        payload: { apiErrorMessage },
      });
    }
  };

const setUpdating = () => ({
  type: SubscriptionActionType.SET_UPDATING,
});
