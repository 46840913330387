import { ReducerActionProps } from "../models/app.model";
import {
  SurveyActionType,
  SurveyStateProps,
} from "../models/survey-redux.model";

const initialState = {
  surveyDisplayMode: undefined,

  logoUrl: null,
  orgName: null,
  isCentriqeLogo: true,

  survey: null,
  showOrgLogoInNavbar: false,
  totalPagesArrayLen: 0,

  surveyInstanceId: null,
  adHocParams: null,
  postSurveyPageMessage: null,
  showPostSurveyPage: false,
  hidePoweredByCentriqe: false,

  isLoading: true,
} as SurveyStateProps;

const SurveyReducer = (state = initialState, action: ReducerActionProps) => {
  switch (action.type) {
    case SurveyActionType.SET_DISPLAY_MODE:
      return {
        ...state,
        surveyDisplayMode: action.payload,
      };

    case SurveyActionType.SET_SURVEY_INSTANCE_FROM_API:
      return {
        ...state,
        logoUrl: action.payload.logoUrl,
        orgName: action.payload.orgName,
        isCentriqeLogo: action.payload.isCentriqeLogo,
        postSurveyPageMessage: action.payload.postSurveyPageMessage,
        survey: action.payload.survey,
        showOrgLogoInNavbar: action.payload.showOrgLogoInNavbar,
        totalPagesArrayLen: action.payload.totalPagesArrayLen,
        hidePoweredByCentriqe: action.payload.hidePoweredByCentriqe,
        showPostSurveyPage: false,
        surveyDisplayMode: action.payload.surveyDisplayMode,
        ...(!!action.payload?.surveyInstanceId && {
          surveyInstanceId: action.payload.surveyInstanceId,
        }),
        ...(!!action.payload?.adHocParams && {
          adHocParams: action.payload.adHocParams,
        }),
        isLoading: false,
      };

    case SurveyActionType.SET_DEFAULTS_AFTER_FAILED_SURVEY_API:
      return {
        ...state,
        orgName: null,
        isCentriqeLogo: true,
        survey: null,
        showOrgLogoInNavbar: false,
        totalPagesArrayLen: 0,

        showPostSurveyPage: true,
        hidePoweredByCentriqe: false,
        logoUrl: action.payload.logoUrl,
        postSurveyPageMessage: action.payload.postSurveyPageMessage,
        surveyDisplayMode: action.payload.surveyDisplayMode,

        isLoading: false,
      };

    case SurveyActionType.SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case SurveyActionType.SET_DEFAULTS_FOR_NO_SURVEY_ALLOWED:
      return {
        ...state,
        showPostSurveyPage: true,
        hidePoweredByCentriqe: action.payload.hidePoweredByCentriqe,
        logoUrl: action.payload.logoUrl,
        postSurveyPageMessage: action.payload.postSurveyPageMessage,
        surveyDisplayMode: action.payload.surveyDisplayMode,
        orgName: action.payload.orgName,
        isCentriqeLogo: action.payload.isCentriqeLogo,
        isLoading: false,
      };

    case SurveyActionType.SET_POST_SURVEY_PAGE_PROPS:
      return {
        ...state,
        postSurveyPageMessage: action.payload.postSurveyPageMessage,
        showPostSurveyPage: action.payload.showPostSurveyPage,
        showAdhocAddlInputPage: false,
        adHocSurveyUserData: null,
        isLoading: false,
      };

    case SurveyActionType.SET_SURVEY_INSTANCE_ID:
      return {
        ...state,
        surveyInstanceId: action.payload,
      };

    default:
      return state;
  }
};

export default SurveyReducer;
