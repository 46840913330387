/** 25082022 - Gaurav - CA-1428: Listen to tablet mode as well  */
import { useLayoutEffect, useState } from "react";

const usePageResize = () => {
  const hasWindow = typeof window !== "undefined";

  const [pageResizeState, setPageResizeState] = useState({
    isMobileView: !!window?.matchMedia("(max-width: 500px)")?.matches,
    isTabletView: !!window?.matchMedia("(max-width: 820px)")?.matches,
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      setPageResizeState((previousState) => ({
        ...previousState,
        isMobileView: !!window?.matchMedia("(max-width: 500px)")?.matches,
        isTabletView: !!window?.matchMedia("(max-width: 820px)")?.matches,
      }));
    };

    window.addEventListener("resize", handleResize);

    // clean-up
    return () => {
      window.removeEventListener("resize", handleResize);
    };

    //eslint-disable-next-line
  }, [hasWindow]);

  return {
    isMobileView: pageResizeState.isMobileView,
    isTabletView: pageResizeState.isTabletView,
  };
};

export default usePageResize;
