import React from "react";

import Box from "@mui/material/Box";
import { connect } from "react-redux";

const ColouredBox = (props: any) => {
  return (
    <Box
      bgcolor={props.prefersDarkMode ? "secondary.main" : "#ffffff"}
      padding={props?.padding ?? "20px"}
      borderRadius={props?.borderRadius ?? "5px"}
      border={props?.border ?? ".5px solid"}
      borderColor={
        !!props?.highlightRequiredFields
          ? "red"
          : props.prefersDarkMode
          ? "secondary.light"
          : "#f3f3f3"
      }
    >
      {props.children}
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  prefersDarkMode: state.app.prefersDarkMode,
});

export default connect(mapStateToProps)(ColouredBox);
