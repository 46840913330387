import { AlertActionType } from "../models/alert.model";

export const displaySavedDataAlert = () => ({
  type: AlertActionType.SHOW_SAVED_DATA_ALERT,
});
export const hideSavedDataAlert = () => ({
  type: AlertActionType.HIDE_SAVED_DATA_ALERT,
});

export const displayApiErrorAlert = () => ({
  type: AlertActionType.SHOW_API_ERROR_ALERT,
});
export const hideApiErrorAlert = () => ({
  type: AlertActionType.HIDE_API_ERROR_ALERT,
});

export const showLoading = () => ({
  type: AlertActionType.SET_MAIN_LOADING_ON,
});
export const hideLoading = () => ({
  type: AlertActionType.SET_MAIN_LOADING_OFF,
});

export const hideAllAlerts = () => ({ type: AlertActionType.HIDE_ALL_ALERTS });
