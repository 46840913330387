import {
  AppActionType,
  AppStateProps,
  ReducerActionProps,
} from "../models/app.model";

const initialState = {
  isApple: false,
  prefersDarkMode: false,
} as AppStateProps;

const AppReducer = (state = initialState, action: ReducerActionProps) => {
  switch (action.type) {
    case AppActionType.SET_OS_TYPE:
      return {
        ...state,
        isApple: action.payload.isApple,
        isApplePhone: action.payload.isApplePhone, //12122022 - Gaurav - CA-1633 fix: determine if user is on iPhone
      };

    case AppActionType.SET_DARK_MODE_PREFERENCE:
      return {
        ...state,
        prefersDarkMode: action.payload,
      };

    default:
      return state;
  }
};

export default AppReducer;
